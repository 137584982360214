import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    config:{
      usdn_asset: process.env.VUE_APP_usdn_asset,
      nodeURL: process.env.VUE_APP_nodeURL,
      etherscanURL: process.env.VUE_APP_etherscanURL,
      wavesexplorerURL: process.env.VUE_APP_wavesexplorerURL,
      signArtApiURL: process.env.VUE_APP_signArtApiURL,
      gateway_dapp_addr_eth: process.env.VUE_APP_gateway_dapp_addr_eth,
      gateway_dapp_addr_waves: process.env.VUE_APP_gateway_dapp_addr_waves,
      dapp_addr_artworks: process.env.VUE_APP_dapp_addr_artworks,
      dapp_addr_users: process.env.VUE_APP_dapp_addr_users,
      providerUrl: process.env.VUE_APP_providerUrl,
      network: process.env.VUE_APP_network,
      gateway_fee_oracle: process.env.VUE_APP_gateway_fee_oracle,
      gateway_asset_accepted: process.env.VUE_APP_gateway_asset_accepted,
      gateway_asset_accepted_decimal: process.env.VUE_APP_gateway_asset_accepted_decimal,
      saIpfsGateway: process.env.VUE_APP_saIpfsGateway,
      ipfsGateway: process.env.VUE_APP_ipfsGateway,
      gateway_dapp_contract_abi: null,
      networkName: process.env.VUE_APP_networkName,
      ETH_NODE_HTTPS_default: process.env.ETH_NODE_HTTPS_default,
      ETH_NODE_ID: process.env.VUE_APP_ETH_NODE_ID,
      ETH_NODE_HTTPS: process.env.VUE_APP_ETH_NODE_HTTPS,
      ETH_NODE_WSS: process.env.VUE_APP_ETH_NODE_WSS,
      ETH_NODE_CHAIN_ID: process.env.VUE_APP_ETH_NODE_CHAIN_ID,
      confirmations_waves_blocks: 0,
      confirmations_eth_blocks: 0,
    },
    gatewayStatus: {
      dappStatus: true,
      pausedReason: '',
      dappStatusETH: true,
      pausedReasonETH: ''
    }
  },
  mutations: {
    setABI: function(state, abi){
      console.log("abi in mutation")
      console.log(abi)
      state.config.gateway_dapp_contract_abi = abi
      return
    },
    setGatewayStatus: function(state, status){
      if(status.dappStatus.length){
        state.gatewayStatus.dappStatus = status.dappStatus[0].value
        state.gatewayStatus.pausedReason = status.dappStatus[1].value
      }
      if(status.dappStatusETH.status){
        state.gatewayStatus.dappStatusETH = status.dappStatusETH.status._status
        state.gatewayStatus.pausedReasonETH = status.dappStatusETH.status._message
      }
      return
    },
    setConfirmationsBlocks: function(state, data) {
      this.state.config.confirmations_waves_blocks = data.confirmations_waves_blocks
      this.state.config.confirmations_eth_blocks = data.confirmations_eth_blocks
      return
    }
  },
  actions: {
    getConfirmationsBlocks: async function(){
      let config_confirmations_from_waves, config_confirmations_from_eth
        try{
          config_confirmations_from_waves = await fetch(`${this.state.config.nodeURL}/addresses/data/${this.state.config.gateway_dapp_addr_waves}?matches=config_confirmations_from_waves`)
          config_confirmations_from_waves = await config_confirmations_from_waves.json()
        }catch(err){
          console.log(err)
        }
      
        try{
          config_confirmations_from_eth = await fetch(`${this.state.config.nodeURL}/addresses/data/${this.state.config.gateway_dapp_addr_waves}?matches=config_confirmations_from_eth`)
          config_confirmations_from_eth = await config_confirmations_from_eth.json()
        }catch(err){
          // if error, email alert
        }
        let result = {confirmations_waves_blocks: parseInt(config_confirmations_from_waves[0].value), confirmations_eth_blocks: parseInt(config_confirmations_from_eth[0].value)}
        await this.commit('setConfirmationsBlocks', result)
        return result

    },
    getABI: async function(){
      let getABI = await fetch(this.state.config.signArtApiURL+"/gateway/eth/abi")
      getABI = await getABI.json()
      await this.commit('setABI', getABI.abi)
      return
    },
    checkDappStatus: async function(){
      let _this = this
      let dappStatus = await fetch(this.state.config.nodeURL+`/addresses/data/${this.state.config.gateway_dapp_addr_waves}?matches=conf_dapp_is_running%7Cconf_maintenance_msg`)
      dappStatus = await dappStatus.json()
      let dappStatusETH = await fetch(this.state.config.signArtApiURL+`/gateway/eth/status`, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          abi: _this.state.config.gateway_dapp_contract_abi
        })
      })
      dappStatusETH = await dappStatusETH.json()
      await this.commit('setGatewayStatus', {dappStatusETH:dappStatusETH, dappStatus:dappStatus})
      return
    },
  },
  modules: {
  }
})
