<template>
  <div class="nft position-relative w-100">
    <div class="wrap_item" :class="{'isLocked': !crosschainAllowed}" v-if="isLoaded">
          <div class="locked" v-if="!crosschainAllowed"><i class="fa-solid fa-lock" title="This NFT isn't open for crosschain transfer" v-b-tooltip.hover.top></i></div>
          <div class="attributes">
            
              #{{issueNumber[0]}} of {{issueNumber[1]}} - id: {{ nftInfo.name }}
            </div>
          <img :srcset="proxyDisplay || emptySrc" class="thumb"/>
          <div class=" w-100">
            <h3 class="mb-0">{{nft.art_name}}</h3>
          </div>

    </div>
    <div v-else>
      Loading info on waves
    </div>
  </div>
</template>

<script>
import encodeUrl from 'encodeurl';

export default {
  props: ["nftInfo","index"],
  data(){
    return {
      isLoaded: false,
      dapp_addr_artworks: this.$store.state.config.dapp_addr_artworks,
      dapp_addr_users: this.$store.state.config.dapp_addr_users,
      nodeURL: this.$store.state.config.nodeURL,
      signArtApiURL: this.$store.state.config.signArtApiURL,
      ipfsGateway: this.$store.state.config.saIpfsGateway,
      issueNumber: null,
      maxissuable: null,
      flag: null,
      nftInfoLocal: this.nftInfo,
      nft: [],
      emptySrc: require("@/assets/images/no_display.svg"),
      crosschainAllowed: false
    }
  },

  created: async function(){
    await this.getNFTArtinfo()
  },
  methods: {
    getOrderedArtworks: function(data){
        let newData = Object.values(data).reduce((acc, curr) => {
        const _key = curr.key;
        let getAddress = _key.substr(_key.lastIndexOf("_")+1, 35);
        let getId = _key.substr(0, _key.lastIndexOf("_"));
        getId = getId.substr(getId.lastIndexOf("_")+1, 44);
        const getKeyWithoutAddr = _key.substr(0, _key.lastIndexOf("_"));
        const getKeyWithoutTxid = getKeyWithoutAddr.substr(0, getKeyWithoutAddr.lastIndexOf("_"));
        if (!acc[getId]) {
          acc[getId] = [];
        }
          acc[getId]["" + getKeyWithoutTxid] = curr.value;
          acc[getId].user_addr = getAddress
          acc[getId].txid = getId   
        return acc;
      }, {});
      let orderedData = Object.values(newData).sort(function(a,b){
        return new Date(b.art_date) - new Date(a.art_date);
      });
    return orderedData
  },
  getIpfsLink: function(string) {
      if(string!="" && string!=undefined){
        let cid_name = string.split("/");
        return "https://" +this.ipfsGateway+"/"+encodeURIComponent(cid_name[0])+"/" +encodeURIComponent(cid_name[1]);
      }else{
        return ""
      }
    },
    getProxyImage: async function(cid){
      let imgixData =[{id: null, url: this.getIpfsLink(cid)}]
      let response = await fetch(this.signArtApiURL+"/imageboss", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
          },
          body: JSON.stringify(imgixData)
        });
        const imgix = await response.json();
        console.log("imgix", imgix)
      return "https://img.imageboss.me/signart/width/80/animation:true,format:auto/"+imgix.res[0].url
    },
    getNFTArtinfo: async function(){
          let _this =this
          let regex
          try{

            let originTx = await fetch(this.nodeURL+"/transactions/info/"+this.nftInfo.originTransactionId)
            originTx = await originTx.json()

            if(originTx.payload){
              originTx = originTx.payload
            }

            regex = encodeUrl("^art(.*)"+ originTx.call.args[0].value +"_"+ originTx.call.args[1].value)

            let regexArtist = encodeUrl("^user_ERC20_"+ originTx.call.args[1].value)
            let artistInfo = await fetch(this.nodeURL+"/addresses/data/"+this.dapp_addr_users+"?matches="+regexArtist)
            artistInfo = await artistInfo.json()

            if(artistInfo[0] && artistInfo[0].value.length == 42){
              this.crosschainAllowed = true
            }else{
              this.crosschainAllowed = false
            }
            
            
            let nftInfo = await fetch(this.nodeURL+"/addresses/data/"+this.dapp_addr_artworks+"?matches="+regex)
            nftInfo = await nftInfo.json()

            let nftFiltered = this.getOrderedArtworks(nftInfo)
            if(nftFiltered[0]){
              this.flag = nftFiltered[0].art_flag
              this.nft = nftFiltered[0]
            }
            let tempDisplay = Object.prototype.hasOwnProperty.call(nftFiltered[0], "art_crawler_cid") && nftFiltered[0].art_crawler_cid != "" ? nftFiltered[0].art_crawler_cid : nftFiltered[0].art_display_cid
            this.proxyDisplay = await this.getProxyImage(tempDisplay)

            // Get issued nft for this artwork to retrieve the issue number 
            let getSold = Object.keys(nftFiltered[0]).filter( function(key) {
              return key.includes("sold") 
            })
            let getSoldMapped = getSold.map(function(key){ return { key: key, value : nftFiltered[0][key]} })
            let issueNumber
            getSoldMapped.map( function(obj){
              return obj.value.includes(_this.nftInfo.assetId) ? issueNumber = [obj.key.split("_")[2], obj.key.split("_")[4]] : false
            })

            if(issueNumber){
              _this.issueNumber = issueNumber
            }else{
              _this.issueNumber = "N/A"
            }

            _this.isLoaded = true
            // this.getCurrentUrl()
          }catch( err ){
            console.log(err) 
          }
    }
  }
}
</script>
<style lang="scss" scoped>
.locked{
    font-size: 16px;
    color: #424242;
    position: absolute;
    z-index: 5;
    right: 6px;
    top: 1px;
}
</style>